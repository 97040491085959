.home_root_container {
    display: flex;
    width: 100%;
    height: fit-content;
    padding-top: 40vh;
    padding-left: 20%;
    text-align: -webkit-center;
    padding-right: 20%;
}

.clock_row {
    display: flex;
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    cursor: pointer;
    padding: 5px;
    height: 40px;
}

.clock_list {
    margin-right: 10px;
    border-top: 1px solid;
}

@media only screen and (max-width: 1024px) {
    .home_root_container {
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;
    }

    .clock_list {
        border-top: 1px solid;
    }
}