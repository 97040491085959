.contact_root {
    width: 80%;
    flex: 1;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    background-color: #3a3a3a;
    min-height: 300px;
}

.contact_form_parent {
    width: 50%;
    margin-top: 40px;
    position: relative;
}

@media only screen and (max-width: 950px) {

    .contact_form_parent {
        width: 100%;
    }

    .contact_root {
        flex-direction: column;
    }
}