#PopUpVideo {
    width: 100%;
    height: 100vh;
    padding-top: 70px;
    background-color: rgba(247, 247, 247, 0.51);
    align-items: center;
    alignment: center;
    align-content: center;
}

#videoContainer {

}

.container2 {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;

}


body {
    background-color: #666;
}

#exit-button {
    position: relative;
    width: 50px;

    height: 50px;
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 20px;
    line-height: 20px;
    color: #5E5E5E;
    margin-left: 5%;
    border: 3px solid rgba(94, 94, 94, 0.8);
    font-size: 25px;
    text-align: center;
    text-shadow: 0px 0px 1px black;
}

.round-button {

    box-sizing: border-box;
    display: block;
    object-fit: contain;
    width: 80px;
    height: 80px;
    padding-top: 14px;
    padding-left: 8px;
    line-height: 20px;
    margin-top: 50px;
    border: 3px solid rgba(94, 94, 94, 0.8);
    border-radius: 50%;
    color: #f5f5f5;
    text-align: center;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    font-size: 20px;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px black;
    text-shadow: 0px 0px 6px black;
}

.round-button:hover {
    background-color: rgba(0, 0, 0, 0.0);
    box-shadow: 0px 0px 13px black;
    text-shadow: 0px 0px 13px black;
}

.movie_frame {
    position: relative;
    min-width: 600px;
    min-height: 500px;

    align-self: center;
    width: 50%;
    height: 45%;
    object-fit: contain;

}

@media only screen and (max-width: 950px) {

    .movie_frame {


        width: 80%;
        height: 35%;
        margin-left: 5%;
        min-width: 0;
        min-height: 0;

    }

    #exit-button {
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 6px;
        padding-right: 0px;
        font-size: 20px;
        margin-left: 0%;

    }

    .round-button {
        width: 60px;
        height: 60px;
        padding-top: 10px;
        margin-top: 10%;

    }
}