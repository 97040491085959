body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif,"Assistant Light";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: rtl;
    text-align: right;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    font-family: "Assistant Light";
}

img.contain {
    font-family: 'object-fit: contain;';
    font-family: "Assistant Light";
}

img.cover {
    font-family: 'object-fit: cover;';
    font-family: "Assistant Light";
}

img.scaleDown {
    font-family: 'object-fit: scale-down;';
    font-family: "Assistant Light";
}

div {
    -ms-overflow-style: none;
}
.App {
    text-align: right;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    font-size: 20px;
    background-color: rgba(255, 255, 255,1);
    font-family: Assistant;}

div {
    box-sizing: border-box;
}



.flex_row {
    display: flex;
    width: 100%;
}

h4 {
    font-size: 2em;
    text-align: center;
    margin: 1.33em 0 1.33em 0;
}

a {
    text-decoration: none;
}

@media only screen and (max-width: 950px) {
    .App {
        font-size: 15px;
    }
}

textarea::-webkit-input-placeholder, input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: darkgreen;

    font-family: Assistant;
    opacity: 0.9; /* Firefox */
}

textarea::-ms-input-placeholder, input::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: darkgreen;

    font-family: Assistant;
    opacity: 0.9; /* Firefox */
}

textarea::placeholder, input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: darkgreen;

    font-family: Assistant;
    opacity: 0.9; /* Firefox */
}

.h4 {
    font-size: 2em;
    font-weight: bold;
}

.h4.small {
    font-size: 1.4em;
    margin-bottom: 0;
}

input, textarea {
    box-sizing: border-box;
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1.1rem;
    line-height: 1.5;
    color: #495057;
    border-radius: 5px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #8b8f93;
}

h3 {
    font-size: 1.5em;
}

textarea {
    font-family: Assistant;
}

input {
    font-family: Assistant;
}

#element::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    display: none;
}
.routeSpan {
    cursor: pointer;
    color: #868686;
    margin-left: 20px;
    font-family:"Assistant Light";
}

.routeSpan:hover {
    color: darkgreen;
}

.title, .text {
    font-family: "Assistant Light";
    color: white;
}

.title {
    font-size: 2em;
    font-weight: bold;
}

.text {
    font-size: 1.1em;
}

.icon {
    margin-left: 15px;
    color: #009600;
    cursor: pointer;
}

.container {
    position: fixed;
    left: 0;
    right: 0;
    width: 70%;
    min-width: 650px;
    z-index: 10;
}

.header_title {
    display: flex;
    height: 90%;
    width: 100%;
    flex-direction: row-reverse;
    padding-right: 20px;
}

.gp_logo {
    margin-top: 5px;
    height: 85%;
    max-width: 100%;
    margin-left: 10px;
    cursor: pointer;
}

.drawer_root {
    max-width: 80vw;
    min-width: 200px;
    min-height: 100vh;
    background-color: #373a47;
}

.drawer_list {
    list-style: none;
    width: 100%;
    color: white;
    padding: 30px 0 0;
    margin: 0;
}

.drawer_list.sub {
    padding: 0;
}

.drawer_item {
    font-weight: 400;
    cursor: pointer;
    display: block;
    padding: 8px 16px;
    height: 30px;
}

.drawer_item.sub {
    z-index: 10;
    padding-right: 30px;
    display: flex;
    align-items: center;
}

.header_main_root {
    position: fixed;
    right: 0;
    left: 0;
    pointer-events: none;
    top: 0;
    display: flex;
    z-index: 10;
    flex-direction: column;
    align-items: flex-end;
}

.header_root {
    width: 100%;
    -webkit-transition: height 0.2s ease-out;
    transition: height 0.2s ease-out;
    z-index: 10;
    height: 70px;
    background: rgba(255, 255, 255, 0.85);
    padding: 10px 10px 10px 0;
    pointer-events: all;
}

.header_root.shrinked {
    background-color: white;
    border-bottom: 1px solid;
    height: 50px;
    z-index: 12;
}

@media only screen and (max-width: 950px) {
    .header_title {
        flex-direction: row;
        padding-right: 0;
    }
}
.contact_window_root {
    min-width: 300px;
    background-color: white;
    padding: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    pointer-events: all;
    font-family: Assistant, sans-serif;

}

.contact_window_input {
    margin-top: 3px;
    font-family: Assistant, sans-serif;
}

@media only screen and (max-width: 950px) {
    .contact_window_root {
        width: auto;
        right: 50px;
        font-family: Assistant, sans-serif;
    }
}
.about_section_root {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
    align-items: center;
    cursor: pointer;

}

.imageHolder {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: row-reverse;
    direction: ltr;
    align-items: center;
    align-content: center;
    alignment: center;
    align-self: center;
    padding-right: 60px;
    padding-left: 60px;
    padding-bottom: 40px;
    padding-top: 40px;
    border-radius: 20px;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.5);
}

.imageLogo{
    width: 60px;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    height:60px;

}
.box{
    border: 2px solid darkgreen;
    border-radius: 20px;
    width:250px;
    display: flex;
    direction: rtl;
    flex-direction:column ;
    margin-right: 30px;
    height:350px;
}
.aboutHolder{
    height: 100vh;
}
#title {
    margin-top: 100px;
    font-size: 2.5em;

}
.titleAboutSec{
    color: #393939;
    align-self: center;
    font-size: 30px;
}
.textAbout{
    color: #404040;
    align-self: center;
    width: 100%;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
}
@media only screen and (max-width: 950px) {
    .about_section_root {
        width: 100%;
    }
    .aboutHolder{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    #title {
        margin-top: 20px;
        font-size: 2em;

    }
    .titleAboutSec{

        font-size: 1.8em;
    }
    .textAbout{

        font-size: 19px;
    }
    .box{
        width:300px;
        height:280px;
        margin-right: 0px;

        margin-bottom: 10px;
    }

    .imageHolder {
        height: 90%;
        flex-direction: column;
        align-content: center;
        align-items: center;
        alignment: center;
        width: 100%;
        padding-top: 25px;

    }

    #crisSwi{

        max-height: 150px;
        align-self: center;
        margin-right: 0px;
        padding-bottom: 0px;
        margin-bottom: 10px;


    }
    #switches {
        max-height: 90px;
        align-self: center;
        margin-right: 0px;
        padding-bottom: 0px;
        margin-bottom: 15px;

    }
    #senseIR{
        max-height: 150px;
        align-self: center;
        margin-right: 0px;
        padding-bottom: 0px;
        margin-bottom: 10px;


    }
}

#switches {
    height: 40%;

}
#crisSwi{
    height: 60%;
    margin-right: 2%;

}
#senseIR{
    height: 80%;


}
.ProductImage {

    align-content: baseline;
    justify-content: space-between;
    align-self: flex-end;
    margin-bottom: 10px;
}
.info_title {
    text-align: center;
    margin: 0 0 10px;
    color: rgba(55, 55, 55, 0.92);
}
.info_text {
    text-align: center;
    margin: 0 0 10px;
    font-family: Assistant;

    color: #373737;
}
#PopUpVideo {
    width: 100%;
    height: 100vh;
    padding-top: 70px;
    background-color: rgba(247, 247, 247, 0.51);
    align-items: center;
    alignment: center;
    align-content: center;
}

#videoContainer {

}

.container2 {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;

}


body {
    background-color: #666;
}

#exit-button {
    position: relative;
    width: 50px;

    height: 50px;
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 20px;
    line-height: 20px;
    color: #5E5E5E;
    margin-left: 5%;
    border: 3px solid rgba(94, 94, 94, 0.8);
    font-size: 25px;
    text-align: center;
    text-shadow: 0px 0px 1px black;
}

.round-button {

    box-sizing: border-box;
    display: block;
    object-fit: contain;
    width: 80px;
    height: 80px;
    padding-top: 14px;
    padding-left: 8px;
    line-height: 20px;
    margin-top: 50px;
    border: 3px solid rgba(94, 94, 94, 0.8);
    border-radius: 50%;
    color: #f5f5f5;
    text-align: center;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
    font-size: 20px;
    font-weight: bold;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px black;
    text-shadow: 0px 0px 6px black;
}

.round-button:hover {
    background-color: rgba(0, 0, 0, 0.0);
    box-shadow: 0px 0px 13px black;
    text-shadow: 0px 0px 13px black;
}

.movie_frame {
    position: relative;
    min-width: 600px;
    min-height: 500px;

    align-self: center;
    width: 50%;
    height: 45%;
    object-fit: contain;

}

@media only screen and (max-width: 950px) {

    .movie_frame {


        width: 80%;
        height: 35%;
        margin-left: 5%;
        min-width: 0;
        min-height: 0;

    }

    #exit-button {
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 6px;
        padding-right: 0px;
        font-size: 20px;
        margin-left: 0%;

    }

    .round-button {
        width: 60px;
        height: 60px;
        padding-top: 10px;
        margin-top: 10%;

    }
}
.contact_root {
    width: 80%;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    background-color: #3a3a3a;
    min-height: 300px;
}

.contact_form_parent {
    width: 50%;
    margin-top: 40px;
    position: relative;
}

@media only screen and (max-width: 950px) {

    .contact_form_parent {
        width: 100%;
    }

    .contact_root {
        flex-direction: column;
    }
}

.clock-container {
  position: relative;
  width: 15vw;
  overflow: hidden;
  background: #fff;
  display: block;
  height: 15vw;

  &.styling{
    box-shadow: 0px 0px 10px #888888;
    border-radius: 50%;
    min-width: 120px;
    min-height: 120px;
    margin-bottom: 5%;
    align-self: flex-end;
  }

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.clock-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .background-numbers {
    position: absolute;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    z-index: 1;
    .numbers {
      fill: grey;
    }
  }

  .indicator {
    border-radius: 10px;
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    outline: 1px solid transparent;
    position: absolute;
    bottom: 50%;
    -webkit-transition-duration: 1s;
            transition-duration: 1s;

    &.seconds-indicator {
      width: 0.5%;
      height: 37.5%;
      z-index: 2;
      background-color: #dfc48c;
      &.transition-effect {
      }
    }

    &.minutes-indicator {
      width: 1%;
      height: 30%;
      z-index: 3;
      background-color: #cd6a51;
      &.transition-effect {
      }
    }

    &.hours-indicator {
      width: 1.5%;
      height: 22.5%;
      z-index: 4;
      background-color: #8f9c6c;
      &.transition-effect {
      }
    }
  }

  .indicator-cover {
    position: absolute;
    bottom: 48.5%; /*(50% - (3% /2)) */
    border-radius: 50%;
    width: 3%;
    height: 3%;
    background-color: lightgrey;
    z-index: 5;
  }
}

.digital {
  margin-top: 50px;
  text-align: center;
  font-family: 'Skia', sans-serif;
  font-size: 20px;
}

@media only screen and (max-width: 950px) {
  .clock-container {
    align-self: center;
  }
}
.home_root_container {
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 40vh;
    padding-left: 20%;
    text-align: -webkit-center;
    padding-right: 20%;
}

.clock_row {
    display: flex;
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    cursor: pointer;
    padding: 5px;
    height: 40px;
}

.clock_list {
    margin-right: 10px;
    border-top: 1px solid;
}

@media only screen and (max-width: 1024px) {
    .home_root_container {
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;
    }

    .clock_list {
        border-top: 1px solid;
    }
}
.page_top_root {
    position: relative;
    width: 100%;
    height: 100%;
    alignment: center;
    align-content: center;
    align-items: center;
    vertical-align: center;
    justify-content: center;
}

.picClass {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    vertical-align: center;

}

.appIconRow {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: row-reverse;
    display: flex;
    padding-right: 5vw;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5vw;
    align-items: baseline;
    justify-content: space-between;
}

.appIconHolder {
    width: 100%;

    min-height: 30vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

}

.AppIcon {
    display: flex;
    width: 400px;
    justify-content: space-evenly;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    align-content: center;
    flex-direction: column;
    vertical-align: middle;
}

.appIconImage {
    max-height: 80px;
    flex: 1 1;
    object-fit: contain;
    min-height: 80px;
    padding-bottom: 20px;
    align-content: baseline;
}

#imageLarge {
    width: 0%;

}

.blackImage {

    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    boxShadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

}

.appFront {
    margin-right: 15vw;
    margin-left: 2vw;
    z-index: 0;

    height: -webkit-fit-content;

    height: -moz-fit-content;

    height: fit-content;
    max-height: 650px;
    object-fit: contain;
}

.AppText {
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    word-break: break-word;
    display: block;
    padding-right: 10%;
    padding-left: 10%;
    color: grey;
    font-size: 1em;
}

.page_top_img2 {
    width: 100%;
    height: 40vh;
    padding-bottom: 10vh;

    object-fit: cover;
    font-family: "object-fit: cover;";
    font-family: Assistant;
}

.page_top_img3 {
    width: 100%;
    object-fit: cover;

    height: 100vh;
    margin-top: 70px;
    font-family: Assistant;
}

.page_top_img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    font-family: "object-fit: cover;";
    background-color: white;
    font-family: Assistant;

}
.pageOfficeBack{
    height: 50vh;
}
#titleFirst {
    color: white;
    font-size: 1.6em;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 5%;

}

.aboutInfo {
    color: white;
    padding-left: 5%;
    padding-right: 3%;
    font-size: 1.2em;
    direction: rtl;

}

.radioOption {
    margin: -9px;
    padding: 0;
    width: 20px;
    height: 20px;
    z-index: 11;
}

.aboutExplain {
    color: #6e6e6e;
    font-size: 1.1em;
    padding-right: 1%;
    padding-left: 10%;
    padding-top: 10px;
    margin-top: 7px;


}

.aboutExplainSec {
    color: #535353;
    padding-right: 1%;
    padding-left: 1%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.2em;
    font-family: "Assistant SemiBold";

}

.aboutSecondInfo {
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0);
    width: 100%;
    padding-bottom: 20px;
    padding-right: 2%;
    padding-left: 2%;
    height: 100%;

}

#buttonContainer {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 0px;
    left: 0px;
    right: 0px;
    direction: rtl;
    bottom: 0px;
    justify-content: space-between;
}

.titleAbout {
    color: #003f00;
    display: flex;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 5%;
    font-family: Assistant SemiBold;
    width: 100%;
    direction: rtl;
    margin-bottom: 0px;;
    font-size: 1.5em;

}

#lineContainer {
    margin-right: 25%;

}

.features_list_root {
}

#line {
    width: 3px;
    background: rgba(0, 0, 0, 0.23);
    height: 100%;
    min-height: 500px;
}

.root_text_container {
    padding-left: 20%;
    padding-right: 20%;
    width: 100%;
    color: #3a3a3a;
    font-size: 1.2em;
    padding-bottom:10vh ;
}

.page_top_bg {
    position: absolute;
    height: 60vh;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.6);
    right: 0;
    bottom: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.page_top_bg2 {
    position: absolute;
    height: 40vh;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.6);
    right: 0;
    bottom: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
#pano {
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.5);
}
.allIconContainer {
    flex: 1 1;
    padding-right: 1%;
    margin-top: 10px;
    padding-left: 1%;
    margin-right: 1%;
    margin-left: 1%;
}

.iconRowContainer {
    flex-direction: row-reverse;
    height: 50%;
    padding-right: 10%;
    padding-left: 10%;

    justify-content: space-between;
    display: flex;
    width: 100vw;
    align-items: center;

}

.boldAndGreen {
    font-family: "Assistant SemiBold";
    color: darkgreen;
}

.AdvantageTextTitle {
    text-align: center;
    width: content-box;
    display: block;
    margin-bottom: 10px;
    font-family: "Assistant Light";
    font-size: 2em;
}

.title2 {
    font-size: 2.5em;
    padding-top: 100px;
    align-self: center;
    text-align: center;
    font-family: Assistant;

}

.iconHolder {
    display: flex;
    justify-content: space-evenly;
    height: 400px;
    align-items: center;
    padding-top: 50px;
    align-content: center;
    flex-direction: column;
    vertical-align: middle;
}

.AdvantageText {
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    word-break: break-word;
    display: block;
    padding-right: 10%;
    padding-left: 10%;
    color: grey;
    font-size: 1em;

}

.iconsAdvantage {
    max-height: 110px;
    flex: 1 1;
    object-fit: contain;
    min-height: 110px;
    padding-bottom: 20px;
    align-content: baseline;
}

.page_top_text {
    color: #525252;
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 60vh;
    font-size: 2.5em;
}
.slide{
    background-color: #cd6a51;
}
.page_top_textFAQ {
    color: #666666;
    position: absolute;
    width: 100%;
    height: 10%;
    top: 55%;
    bottom: 35%;

    text-align: center;
    font-size: 2.5em;
}

.page_top_textApp {
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 10px;
    align-self: center;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    font-size: 1.5em;
    background-color: rgba(0, 0, 0, 0.3);

    border: 0px solid black;

}

.page_top_textApp2 {
    color: white;
    padding-right: 20px;
    align-self: center;
    padding-left: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5em;


}

.page_top_text.panoramic {
    right: 15%
}

#imageLarge {
    width: 50%;
    opacity: 0.8;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.appTitle {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
}

.appContainer {
    flex-direction: row;

}

.AppButtons {
    align-self: center;
    height: 50px;
    display: flex;
    margin-top: 15vh;
    width: 320px;
}

@media only screen and (max-width: 1000px) {
    .root_text_container {
        padding-left: 10%;
        padding-right: 10%;
        width: 100%;
    }

    .AppButtons {
        height: 7vh;
        max-height: 60px;
        display: flex;
        justify-content: space-around;
    }

    .appContainer {
        flex-direction: column;
        margin-top: 10px;

    }

    .AdvantageTextTitle {
        margin-top: 50px;
        font-size: 4em;

    }


    .page_top_textApp2 {
        width: 100%;
        font-size: 1.5em;


    }

    .page_top_textApp {
        margin-top: -63vh;

        font-size: 1.2em;
        color: rgba(0, 0, 0, 0.6);
        background-color: rgba(0, 0, 0, 0);


    }

    .appIconRow {
        width: 100%;
        flex-direction: column-reverse;
        padding-right: 1vw;
        padding-left: 1vw;
        justify-content: center;
        padding-top: 0px;
    }

    .appTitle {
        width: 100%;
        height: 100vh;
        justify-content: space-between;

    }

    .appIconHolder {
        width: 100vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;

    }

    .appFront {
        margin-right: 0px;

        align-self: center;
        top: 31vh;
        bottom: 2vh;
        text-align: right;
        direction: ltr;
        max-height: 75vh;
        position: absolute;
    }

    .AppText {
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
        word-break: break-word;
        display: block;
        padding-right: 10%;
        padding-left: 10%;
        color: grey;
        font-size: 1em;
    }

    .AppIcon {
        width: 80vw;
        margin-right: 10vw;
        margin-left: 10vw;
        padding-top: 20px;

    }

    .appIconImage {
        max-height: 90px;
        min-height: 0px;
        flex: 1 1;
        object-fit: contain;
        padding-bottom: 20px;
        align-content: baseline;
    }

    .allIconContainer {
        width: 70vw;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .page_top_img2 {
        margin-top: 20vh;
        height: auto;
    }

    .iconRowContainer {
        flex-direction: column;

    }

    .iconHolder {
        padding-top: 5%;
    }

    .page_top_img, .page_top_bg {
        height: 45vh;
    }

    .radioOption {
        margin: -7px;
        padding: 0;
        width: 15px;
        height: 15px;
    }

    #titleFirst {
        font-size: 20px;
        margin-bottom: 0px;

    }

    #lineContainer {
        margin-right: 5%;

    }

    #imageLarge {
        width: 0px;
        height: 0px;
    }

    .aboutSecondInfo #line #buttonContainer {
        height: 300px;
        max-height: 300px;

    }

    .iconHolder {
        height: 300px;

    }

    .page_top_text {
        bottom: 70vh;
    }

    .AdvantageText {
        font-size: 1.3em;
    }

    .aboutInfo {
        font-size: 13px;
        padding-top: 0%;


    }

    .title2 {
        font-size: 2em;

    }

    .picClass {
        height: 270px;
        width: 100%;
        margin-top: 40px;

    }

    /*
        .page_top_root {
            padding-top: 20%;
            padding-left: 10%;
            padding-right: 10%;

        }*/
    .features_list_root {
        width: unset;
    }

    /*
        .page_top_img {
            height: 100px;


        }*/
    .blackImage {
        width: 100%;
    }

    /*    .page_top_text.panoramic {
            right: 5%
        }*/
}
.question_text {
    margin-right: 30px;
    align-self: center;
    color: #4b4b4b;
}
.icon{
    color: darkgreen;
}
@media only screen and (max-width: 950px) {
    .question_text {
        margin-right: 20px;
    }
}
.slide {
    background-color: white !important;
    height: 60vh !important;
    object-fit: contain;
    align-items: center;
}

.carousel .slide img {
    height: 300px !important;
    width: 200px !important;
}

.imageHolderPic {
    justify-content: center;
    align-items: center;
    height: 80%;
    display: flex;
    flex-direction: row;
    width: 100%;
    direction: ltr;
    align-content: center;


}
.roundButton{
    height: 70px;
    width: 170px;
    border-radius: 20px;
    background-color: rgba(0,0,0,0);
    border: 2px solid darkgreen;
    align-self: center;
    font-size: 18px;

    min-height: -webkit-fit-content;

    min-height: -moz-fit-content;

    min-height: fit-content;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    color: darkgreen;
}
.textPicFirst {
    font-family: Assistant;
    z-index: 3;
    width: 100%;
    text-align: center;
    font-size: 25px;
    color: rgba(6, 6, 6, 0.75);
}

.titlePicFirst {
    font-family: Assistant;
    z-index: 3;
    width: 100%;
    text-align: center;
    font-size: 35px;
    color: #2f2f2f;


}

.PicContainerRow {
    width: 100%;
    height: 70vh;
    display: flex;
    z-index: 4;
    justify-content: space-around;
    flex-direction: row-reverse;
    align-items: baseline;
    position: relative;
    padding-right: 5%;
    padding-left: 10%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

}

.PicContainerCol {
    width: 100%;
    height: 70vh;
    align-content: center;
    align-self: center;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column-reverse;
    align-items: center;
    object-fit: contain;
    padding-top: 0px;
    z-index: 2;
    padding-left: 5%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    padding-right: 5%;
    padding-bottom: 40px;
}

.PicContainerRowR {
    width: 100%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

    height: 70vh;
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: baseline;
    padding-right: 10%;
    padding-left: 5%;
}

.ItemsSmall {
    align-self: center;
    height: 180px;
    z-index: 3;
    margin-bottom: 20px;
    object-fit: contain;
}

.ItemsSmall2 {
    align-self: center;
    object-fit: contain;
    height: 220px;
    z-index: 3;

}

.Items {
    align-self: center;
    height: 350px;
    object-fit: contain;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
    z-index: 3;

}
.ItemsSwitch {
    align-self: center;
    height: 320px;
    object-fit: contain;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
    z-index: 3;

}

.ItemsI {
    align-self: center;
    height: 40vh;
    object-fit: contain;
    z-index: 3;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);

}

.ImageHoldI {
    height: 350px;
    z-index: 3;
    align-self: flex-end;
    object-fit: contain;
}

.ImageHoldS {
    height: 150px;
    z-index: 3;
    align-self: flex-end;
    object-fit: contain;
}

.ImageHold {
    height: 250px;
    z-index: 3;
    align-self: flex-end;
    object-fit: contain;
}

.carousel .control-next.control-arrow, .carousel .control-prev.control-arrow {
    height: 50px;
    top: 80%;
}

.carousel .slide .legend {
    width: 50% !important;
    left: 25% !important;
    margin-left: 0px !important;
}

.titlePic {
    font-family: Assistant;
    font-size: 2em;
    text-align: center;
    padding-bottom: 20px;
    padding-top: -50px;

    align-self: center;
    direction: rtl;
    width: 80%;
    color: #004800;

}

.textPic {
    font-family: Assistant;
    text-align: center;
    width: 60%;
    align-self: center;
    direction: rtl;
    font-size: 1.2em;
    object-fit: contain;
    color: #4e4e4e;

}

.carousel .control-next.control-arrow {
    right: 25% !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid black !important;
}

.control-prev.control-arrow:before {
    border-right: 8px solid black !important;

}

.carousel .control-prev.control-arrow {
    left: 25% !important;
}

.rowStart {
    width: 100%;
    padding-bottom: 2%;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: row;
    direction: ltr;
    align-items: baseline;
    justify-content: space-between;
    padding-left: 7%;
    padding-right: 7%;
}
.switches{
    width:60%;
}
@media only screen and (max-width: 768px) {
    .textPicFirst {
        z-index: 3;
        text-align: center;
        font-size: 4.5vw;
    }
    .roundButton{
        height: 45px;
        border: 1.5px solid darkgreen;
        width: 45px;
        border-radius:100%;
        font-size: 25px;

    }
.switches{
    width:100%;
}
    .PicContainerRow {
        padding-right: 10px;
        padding-left: 10px;

    }
    .ItemsSwitch{
        height: 30vw;

    }

    .PicContainerCol {
        padding-top: 0px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    .PicContainerRowR {
        padding-right: 10px;
        padding-left: 10px;
    }


    .titlePicFirst {
        font-size: 5.5vw;

    }


    .titlePic {

        width: 80%;
        font-size: 6vw;


    }

    .textPic {
        text-align: center;
        width: 90%;
        align-self: center;
        direction: rtl;
        flex: 1 1;
        font-size: 4.1vw;
    }

    .rowStart {
        width: 0px;
        opacity: 0;
        height: 0px;

    }

    .ItemsSmall {
        height: 25vw;
        margin-left: 0;
    }

    .ItemsSmall2 {
        height: 25vw;
        margin-left: 0;

    }

    .Items {
        height: 50vw;

    }

    .ItemsI {
        height: 60vw;


    }

    .ImageHoldI {
        object-fit: contain;
    }

    .ImageHoldS {
        object-fit: contain;
    }

    .ImageHold {
        object-fit: contain;
    }


}
.product_root {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 50px;
    display: flex;
    background-size: cover;
}

.product_title {
    min-width: 150px;
}

.product_item_root {
    width: 200px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    cursor: pointer;
}

.switch_root {
    width: 100%;
    padding: 30px 10%;
    display: flex;
    color: black;
}

p {
    margin-bottom: 0;
}

.switch_root.odd {
    flex-direction: row-reverse;
}

.sense_parent {
    width: 100%;
    margin-top: 50px;
    background-size: cover;
    position: relative;
}

.sensor_parent {
    width: 100%;
    position: relative;
}

@media only screen and (max-width: 950px) {
    .switch_root.dark {
        color: black
    }
    .switch_root {
        flex-direction: column;
    }

    .switch_root.odd {
        flex-direction: column;
    }

    .product_root {
        padding: 0;
        margin: 0;
    }

    .sensor_parent {
        background: white;
    }

    .sense_parent {
        background: white;
    }
}
.textBox{
    width:50%;
    left:25%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    right:25%;
    padding-top:50px;
    padding-bottom:50px;
    padding-right:50px;
    padding-left:50px;
    top:50vh;
    border-radius: 20px;
    position:absolute;
    background-color:rgba(255,255,255,0.7);
}
.OfficeTitle{
    color: #5a5a5a;
    width: 100%;
    position: absolute;
    font-size: 2.5em;
    padding-top: 120px;
    top: 5vh;
    left: 0;
    text-align: center;
}
.OfficeText{
    color: #595959;
    width: 100%;
    font-size: 1.2em;
    text-align: center;
}
.HotelTitle{
    color: #003f00;
    width: 100%;
    font-size: 1.3em;
    padding-bottom: 20px;
    text-align: center;
}
.HotelText{
    color: #636363;
    width: 100%;
    font-size: 1em;
    text-align: right;
}
.officeContainer{
    height: 100vh;
}
.HotelCon{
    flex-direction:row;


}
.textBoxHotel{
    width:30%;
    min-height:-webkit-fit-content;
    min-height:-moz-fit-content;
    min-height:fit-content;
    padding-top:50px;
    padding-bottom:50px;
    padding-right:50px;
    padding-left:50px;
    border-radius: 20px;
    background-color:rgba(255,255,255,0.8);
}
@media only screen and (max-width: 1024px) {
    .textBox{
        width:90%;
        left:5%;
        right:5%;
        padding-top:10px;
        top: 45vh;
        padding-bottom:10px;
        padding-right:10px;
        padding-left:10px;

    }
    .textBoxHotel{
        width:95%;
        padding-top:50px;
        padding-bottom:50px;
        margin-bottom: 20px;
        padding-right:2.5%;
        padding-left:2.5%;
    }
    .HotelCon{
        flex-direction:column;

    }
    .officeContainer{
        height: 120vh;
    }
    .OfficeTitle{
        color: #5a5a5a;
        width: 100%;
        position: absolute;
        font-size: 2.5em;
        padding-top: 120px;
        top: 0vh;
        left: 0;
        text-align: center;
    }
    .OfficeText{
        color: #595959;
        width: 100%;
        font-size: 1.2em;
        text-align: center;
    }
}
.highLineContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    text-align: left;
    height: 100vh;
    direction: ltr;
    background: #0B0B27;
}

.logoHighLine {
    width: 100px;
    object-fit: contain;
    height: 100px;
}

.formContainerParentHighLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #EAECF8;
    border-radius: 10px;
    padding: 30px;
    width: calc(100% - 60px);
}

.headerHighLineContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.headerSubTextHighLine {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    margin-top: -20px;
    margin-bottom: 10px;
}

.headerHighLineParent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.headerTextHighLine {
    font-size: 5rem;
    color: white;
    margin-left: 10px;
}

.inputHighLine {
    color: black;
    font-size: 0.9rem;
    margin: 8px 0;
    text-align: right;
    direction: rtl;
    min-width: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-height: 40px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 4px 8px;
}
.doneMessageHighLine{
    width: 100%;
    display: flex;
    height: 100%;
}
.inputHighLineHalf {
    font-size: 0.9rem;
    direction: rtl;
    color: black;
    text-align: right;
    min-width: calc(50% - 8px);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-height: 40px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 4px 8px;

}
.doneMessageImage{
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.inputHighLineHalf::-webkit-input-placeholder, .inputHighLine::-webkit-input-placeholder {
    color: black;

}
.inputHighLineHalf::-ms-input-placeholder, .inputHighLine::-ms-input-placeholder {
    color: black;

}
.inputHighLineHalf::placeholder, .inputHighLine::placeholder {
    color: black;

}

.highLineFormText {
    text-align: center;
    white-space: pre-wrap;
    color: #5A5E77;
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.submitButtonHighLine {
    font-size: 0.9rem;
    color: white;
    border-radius: 5px;
    background-color: #0b0c27;
    padding: 10px 0;
    width: 100%;
}

.apartmentHighLineContainer {
    display: flex;
    margin: 8px 0;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
}

.formContainerHighLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    min-width: 600px;
    max-width: 700px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.formContainerHighLine form {
    width: 100%;
    height: 100%;
}
@media only screen and (max-width: 800px) {
    .headerTextHighLine{
        font-size: 2rem;
    }
    .logoHighLine{
        height: 40px;
        width: 40px;
    }
    .formContainerHighLine{
        min-width: calc(100vw - 30px);
    }
    .headerSubTextHighLine{
        font-size: 1.2rem;
        margin-top: -10px;
    }
    .formContainerParentHighLine{
        padding: 10px;
        width: 100%;
    }
    .highLineFormText{
        font-size: 1rem;

    }
}
